import { GetterTree, MutationTree } from "vuex";

// src/store/modules/device.ts

type DeviceState = {
  isPc: boolean;
};

const state = () => ({
  isPc: null,
});

const mutations: MutationTree<DeviceState> = {
  setIsPc(state, data) {
    state.isPc = data;
  },
};

const getters: GetterTree<DeviceState, RootState> = {
  _isPc(state) {
    return state.isPc;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
