import { UserInfo } from "@/api";
import { GetterTree, MutationTree } from "vuex";

// src/store/modules/device.ts

export type UserState = {
  userinfo: UserInfo;
};

const state = () => ({
  userinfo: null,
});

const mutations: MutationTree<UserState> = {
  SET_USER_INFO(state, data) {
    state.userinfo = data;
  },
  CLEAR_USER_INFO(state) {
    state.userinfo = null as any;
  }
};

const getters: GetterTree<UserState, RootState> = {
  userInfo: (state) => state.userinfo,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
