import WebSocketService from "@/utils/websocket";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
export function useGlobalSocket() {
  const store = useStore();
  const ws = new WebSocketService("/ws/common");

  ws.setMessageHandler((data) => {
    if (data.type === "TOKEN") {
      // console.log("Trade----------------", data.data);
      store.commit("ws/SET_TOKEN_DATA", data);
      store.commit("ws/SET_BOUNCE_DATA", data);
    }
    if (data.type === "LEVERAGE") {
      store.commit("ws/SET_LEVERAGE_DATA", data);
      store.commit("ws/SET_BOUNCE_DATA", data);
    }
    if (data.type === "BUBBLE") {
      // const { days, news, leverNum, launchSuccess, scale } = data.data;
      store.commit("ws/SET_BUBBLE_DATA", data);
    }
    if (data.type === "WIN") {
      store.commit("ws/SET_WIN_DATA", data.data);
    }
    if (data.type === "VOLUME") {
      store.commit("ws/SET_VOLUME_DATA", Number(data.data));
    }
    if (data.type === "HOT_TOKEN") {
      store.commit("ws/SET_HOT_TOKEN_DATA", data.data);
      store.commit("ws/SET_BOUNCE_DATA", data);
    }
  });
  onMounted(() => {
    ws.init();
  });
}
