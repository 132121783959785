<script setup lang="ts">
import Head from "@/components/Head/index.vue";
import Footer from "./components/footer.vue";
import CookieModal from "./components/CookieModal/index.vue";
import { getSolPriceFromCoinGecko } from "@/api";
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import { setItem } from "./utils/storage";
import { getConfig } from "@/api/trade";
import PumpChat from "@/components/PumpChat/index.vue";
import { useGlobalSocket } from "@/hooks/globalSoket";
const store = useStore();
store.commit("common/SET_LOADING", false);
console.log("initloading");

const setConfig = async () => {
  const config = await getConfig();
  store.commit("config/SET_CONFIG", config);
};
const pollSolPrice = async () => {
  // 立即执行一次
  const price = await getSolPriceFromCoinGecko();
  store.commit("solPrice/SET_SOL_PRICE", price);

  // 设置15分钟轮询
  setInterval(async () => {
    try {
      const price = await getSolPriceFromCoinGecko();
      store.commit("solPrice/SET_SOL_PRICE", price);
    } catch (error) {
      console.error("get SOL price:", error);
    }
  }, 15 * 60 * 1000); // 15分钟 = 15 * 60 * 1000 毫秒
};
useGlobalSocket();
setConfig();
onMounted(async () => {
  console.log("setIsPc", window.innerWidth);
  store.commit("device/setIsPc", window.innerWidth >= 1080);
  setItem("devicecode", window.innerWidth >= 1080 ? "PC" : "H5");
  window.addEventListener("resize", () => {
    console.log("视图变化", window.innerWidth);
    store.commit("device/setIsPc", window.innerWidth >= 1080);
    setItem("devicecode", window.innerWidth >= 1080 ? "PC" : "H5");
  });
  // 初始化钱包
  store.dispatch("wallet/initWalletListener");
  pollSolPrice();
});
</script>

<template>
  <div class="flex flex-col items-center content overflow-y-auto">
    <Head />
    <div class="lg:w-[1200px] w-full flex flex-col flex-1">
      <div
        class="lg:pb-10 px-4 lg:px-0 lg:pt-4 py-4 flex items-center w-full flex-1"
      >
        <router-view />
      </div>
    </div>
    <!-- <PumpChat :popData="popData" /> -->
    <CookieModal />
    <Footer />
  </div>
</template>

<style scoped lang="scss"></style>
