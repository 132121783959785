// messageTemplates.ts
import bs58 from "bs58";
interface MessageConfig {
  domain: string; // 应用域名
  statement: string; // 主要说明
  version: string; // 版本号
  chainId: number; // Solana chainId, mainnet = 1, testnet = 2, devnet = 3
  nonce: string; // 随机数
  issuedAt: string; // 签名时间
  expirationTime?: string; // 过期时间
}

// 生成随机nonce
const generateNonce = (): string => {
  const random = new Uint8Array(32);
  crypto.getRandomValues(random);
  return bs58.encode(random);
};

// 基础消息类
class MessageBuilder {
  protected domain: string;
  protected chainId: number;

  constructor(domain: string, chainId: number = 1) {
    this.domain = domain;
    this.chainId = chainId;
  }

  protected getBaseConfig(): MessageConfig {
    return {
      domain: this.domain,
      chainId: this.chainId,
      version: "1",
      nonce: generateNonce(),
      issuedAt: new Date().toISOString(),
    } as MessageConfig;
  }

  protected buildMessage(config: MessageConfig): string {
    return JSON.stringify(config, null, 2);
  }
}

// 登录消息生成器
class LoginMessageBuilder extends MessageBuilder {
  buildLoginMessage(
    options: {
      statement?: string;
      expirationTime?: string;
    } = {}
  ): string {
    const config: MessageConfig = {
      ...this.getBaseConfig(),
      statement:
        options.statement ||
        "Sign this message to prove you own this wallet and proceed with login.",
      expirationTime: options.expirationTime,
    };

    return `
  ${config.domain} wants you to sign in with your Solana account:
  ${config.statement}
  
  URI: https://${config.domain}
  Version: ${config.version}
  Chain ID: ${config.chainId}
  Nonce: ${config.nonce}
  Issued At: ${config.issuedAt}
  ${config.expirationTime ? `Expiration Time: ${config.expirationTime}` : ""}
      `.trim();
  }
}

// 交易确认消息生成器
class TransactionMessageBuilder extends MessageBuilder {
  buildTransactionMessage(options: {
    action: string;
    amount?: string;
    token?: string;
    recipient?: string;
    expirationTime?: string;
  }): string {
    const { action, amount, token, recipient, expirationTime } = options;

    const config: MessageConfig = {
      ...this.getBaseConfig(),
      statement: `Confirm ${action} transaction`,
      expirationTime,
    };

    return `
  ${config.domain} requests your confirmation for the following transaction:
  
  Action: ${action}
  ${amount ? `Amount: ${amount} ${token || "SOL"}` : ""}
  ${recipient ? `Recipient: ${recipient}` : ""}
  
  URI: https://${config.domain}
  Version: ${config.version}
  Chain ID: ${config.chainId}
  Nonce: ${config.nonce}
  Issued At: ${config.issuedAt}
  ${expirationTime ? `Expiration Time: ${expirationTime}` : ""}
      `.trim();
  }
}

// 操作确认消息生成器
class ActionMessageBuilder extends MessageBuilder {
  buildActionMessage(options: {
    action: string;
    details?: Record<string, any>;
    expirationTime?: string;
  }): string {
    const { action, details, expirationTime } = options;

    const config: MessageConfig = {
      ...this.getBaseConfig(),
      statement: `Confirm ${action}`,
      expirationTime,
    };

    let detailsString = "";
    if (details) {
      detailsString = Object.entries(details)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n");
    }

    return `
  ${config.domain} requests your approval for the following action:
  
  Action: ${action}
  ${detailsString ? `\nDetails:\n${detailsString}` : ""}
  
  URI: https://${config.domain}
  Version: ${config.version}
  Chain ID: ${config.chainId}
  Nonce: ${config.nonce}
  Issued At: ${config.issuedAt}
  ${expirationTime ? `Expiration Time: ${expirationTime}` : ""}
      `.trim();
  }
}

// 导出工具类
export const createMessageBuilders = (domain: string, chainId: number = 1) => {
  return {
    login: new LoginMessageBuilder(domain, chainId),
    transaction: new TransactionMessageBuilder(domain, chainId),
    action: new ActionMessageBuilder(domain, chainId),
  };
};

// 使用示例
export const messageExamples = {
  // 登录消息
  loginMessage: createMessageBuilders("example.com").login.buildLoginMessage({
    statement: "Sign this message to log in to Example App",
    expirationTime: new Date(Date.now() + 30 * 60 * 1000).toISOString(), // 30分钟后过期
  }),

  // 转账确认消息
  transferMessage: createMessageBuilders(
    "example.com"
  ).transaction.buildTransactionMessage({
    action: "transfer",
    amount: "1.5",
    token: "SOL",
    recipient: "8xyt...",
    expirationTime: new Date(Date.now() + 5 * 60 * 1000).toISOString(), // 5分钟后过期
  }),

  // NFT铸造确认消息
  mintMessage: createMessageBuilders("example.com").action.buildActionMessage({
    action: "mint NFT",
    details: {
      collection: "Example Collection",
      price: "1 SOL",
      quantity: 1,
    },
    expirationTime: new Date(Date.now() + 10 * 60 * 1000).toISOString(), // 10分钟后过期
  }),
};
