import request from "@/utils/http";

/**
 * 配置信息
 */
export interface Config {
    /**
     * pumpup合约地址
     */
    pumpupProgramId: string;
    /**
     * 平台手续费地址
     */
    feePublicKey: string;
}

/**
 * 获取系统配置
 */
export const getConfig = () => {
    return request.get("/config/get") as Promise<Config>;
};
