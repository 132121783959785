import { getSubStr } from "@/utils/common";
import dayjs from "dayjs";
import { ref } from "vue";
interface CardState {
  data: any;
  timestamp: number;
}
//处理卡片hooks
export const useBounceCardData = () => {
  const bounceCards = ref<CardState[]>(
    Array(4)
      .fill(null)
      .map(() => ({
        data: null,
        timestamp: 0,
      }))
  );
  const addData = (newData: any) => {
    let index = -1;

    for (let i = 0; i < bounceCards.value.length; i++) {
      if (bounceCards.value[i].data === null) {
        index = i;
        break;
      }
    }
    if (index === -1) {
      index = Math.floor(Math.random() * 4);
    }
    const newObj = {
      data: newData,
      timestamp: Date.now(),
    };
    const newDataList = [...bounceCards.value];
    newDataList[index === -1 ? 0 : index] = newObj;
    bounceCards.value = newDataList;
  };
  const receiveData = (newData: any) => {
    if (newData) {
      //处理交易数据
      if (newData.type === "HOT_TOKEN") {
        const { data } = newData;
        const user = getSubStr(data.user || "");
        const solAmount = Number(data.solAmount).toFixed(2);
        const tradeType = data.tradeType === "1" ? "Bought" : "Sold";
        addData({
          ...data,
          type: newData.type,
          user,
          solAmount,
          tradeType,
        });
        //处理代币数据
      } else if (newData.type === "TOKEN") {
        const user = getSubStr(newData.data.creator || "");
        const time = dayjs(Number(newData.data.tradeTime)).format("DD/MM/YY");
        addData({
          ...newData.data,
          type: newData.type,
          user,
          time,
        });
      } else if (newData.type === "LEVERGE") {
        const time = dayjs(Number(newData.data.leverageTime)).format(
          "DD/MM/YY"
        );
        addData({
          ...newData.data,
          type: newData.type,
          time,
        });
      }
    }
  };
  return {
    bounceCards,
    receiveData,
  };
};
