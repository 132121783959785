<template>
  <div v-if="isBlocked" class="h5-overlay">
    <div class="h5-floating-content">
      <div class="h5-title">Cookie settings</div>
      <div class="h5-content">
        We use cookies to provide you with the best possible experiendce. They
        also allow us to analyze user behavior in order to constantly improve
        the website for you
      </div>
      <div class="h5-buttons" @click="handleConfirm">
        <div class="h5-button-yellow">Accept all</div>
        <div class="h5-button-green">Accept Selection</div>
      </div>
      <div class="h5-buttons h5-green">Reject all</div>
      <div class="h5-select">
        <div class="h5-checkbox-item">
          <label class="h5-custom-checkbox">
            <input type="checkbox" checked />
            <span class="h5-checkmark"></span>
            Necessary
          </label>
        </div>
        <div class="h5-checkbox-item">
          <label class="h5-custom-checkbox">
            <input type="checkbox" />
            <span class="h5-checkmark"></span>
            Analytics
          </label>
        </div>
        <div class="h5-checkbox-item">
          <label class="h5-custom-checkbox">
            <input type="checkbox" />
            <span class="h5-checkmark"></span>
            Preferences
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
const isBlocked = ref(true);
const COOKIE_CONSENT_KEY = "cookie_consent";
// 检查是否已经同意过 cookie 政策
onMounted(() => {
  const hasConsent = localStorage.getItem(COOKIE_CONSENT_KEY);
  if (hasConsent) {
    isBlocked.value = false;
  }
});
const handleConfirm = () => {
  localStorage.setItem(COOKIE_CONSENT_KEY, "true");
  isBlocked.value = false;
};
</script>
<style scoped lang="scss">
/* 悬浮层覆盖整个页面 */
.h5-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: flex-end; /* 底部悬浮 */
  z-index: 9999;
}

/* 底部悬浮内容 */
.h5-floating-content {
  background-color: #252B34;
  width: 100%;
  height: 300px;
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  justify-content: around;
  align-items: center;
  .h5-title {
    width: 100%;
    height: 20px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #ADB8C9;
  }
  .h5-content {
    width: 100%;
    height: 80px;
    text-align: center;
    display: flex;           // 添加
    align-items: center;     // 添加
    justify-content: center; // 添加
    padding: 0 20px;        // 添加，提供左右边距
    font-size: 14px;
    font-weight: 500;
    color: #757F8D;
  }
  .h5-buttons {
    margin-top: 10px;
    width: 280px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
    .h5-button-yellow {
      width: 135px;
      height: 35px;
      background-color: #FCD535;
      border-radius: 4px;
      text-align: center;
      line-height: 31px;
      border: 1px solid rgba(0, 0, 0, 1);
    }
    .h5-button-green {
      width: 135px;
      height: 35px;
      background-color:#364251;
      border-radius: 4px;
      text-align: center;
      line-height: 31px;
      color: #E9EDF3;
    }
  }
  .h5-green {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 135px;
    height: 35px;
    background-color: #354251;
    border-radius: 4px;
    text-align: center;
    line-height: 31px;
    color: #E9EDF3;
  }
  .h5-select {
    margin-top: 20px;
    width: 332px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #ADB8C9;
    .h5-custom-checkbox {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      font-size: 14px;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .h5-checkmark {
        position: absolute;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: transparent;
        border: 1px solid #ADB8C9;
        border-radius: 3px;
      }

      &:hover input:not(:disabled) ~ .h5-checkmark {
        border-color: #4caf50;
      }

      input:checked ~ .h5-checkmark {
        background-color: #FCD535;
        // border-color: #FCD535;
      }

      input:disabled ~ .h5-checkmark {
        opacity: 0.6;
        cursor: not-allowed;
      }

      .h5-checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 4px;
        top: 1px;
        width: 5px;
        height: 9px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      input:checked ~ .h5-checkmark:after {
        display: block;
        border-color: black;
      }
    }
  }
}
</style>
