<template>
  <div class="wallet-button-content">
    <wallet-multi-button>
      <template v-slot:default="scope">
        <slot v-bind="scope"></slot>
      </template>
      <template v-slot:modal="scope">
        <div class="wallet-modal-cont h5-wallet-modal-cont wallet-modal-cont-pc" style="z-index: 2;">
              <div class="wallet-head wallet-head-pc h5-wallet-head flex items-center justify-between">
                  <div class="wallet-modal-title h5-wallet-modal-title wallet-modal-title-pc">Connect your wallet
                  </div>
                  <div class="wallet-modal-close h5-wallet-modal-close" @click="scope.closeModal()">
                      <img src="@/assets/close.svg" alt="">
                  </div>
              </div>
              <div class="wallet-modal-body h5-wallet-modal-body">
                  <div class="wallet-item h5-wallet-item flex items-center justify-between"
                      v-for="(item, index) in scope.walletsToDisplay.value.concat(scope.hiddenWallets.value)"
                      :key="index" @click="
                          scope.selectWallet(item.adapter.name);
                      scope.closeModal();
                      ">
                      <div class="wallet-name h5-wallet-name">{{ item.adapter.name }}</div>
                      <img class="wallet-icon h5-wallet-icon" :src="item.adapter.icon" alt="">
                      <!-- <div v-if="item.readyState === 'Installed'">Detected</div> -->
                  </div>
              </div>
              <div class="wallet-modal-footer h5-wallet-modal-footer">
                  <span class="footer-text-one h5-footer-text-one">By selecting Connect,you have accepted</span>
                  <span class="footer-text-two h5-footer-text-two">Terms of Service, Privacy Policy</span>
              </div>
        </div>
      </template>
    </wallet-multi-button>
  </div>
</template>
<script lang="ts" setup>
import { WalletMultiButton } from "solana-wallets-vue";
import { getSubStr } from "@/utils/common";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { UserInfo } from "@/api";
</script>
<style lang="scss" scoped>
.wallet-modal-cont {
  background: #252B34;
  // border: 4px solid #591c02;
  border-radius: 10px;

  .wallet-head {
    color: #0c1c23;
  }

  .wallet-item {
    background: #1D2028;
    padding: 10px 18px;
    margin-bottom: 20px;
    border-radius: 5px;

    .wallet-name {
      color: #757F8D;
      font-size: 19px;
      font-weight: 700;
    }
  }

  .wallet-modal-footer {
    // width: 90%;
    // margin: auto;
    text-align: center;

    span {
      display: block;
    }

    .footer-text-one {
      color: #E9EDF3;
      margin-bottom: 8px;
      font-size: 16px !important;
    }

    .footer-text-two {
      color: #FCD535;
      font-size: 14px;
    }
  }
}

/* PC样式 */
@media screen and (min-width: 1080px) {
  .wallet-modal-cont-pc {
    padding: 20px;
    width: 500px;

    .wallet-head-pc {
      margin-bottom: 20px;

      .wallet-modal-title-pc {
        color: #E9EDF3;
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
  .wallet-icon {
  width: 40px;
  height: 40px;
  border-radius: 25px;
}
  .wallet-btn {
      margin-top: 10px;
      box-sizing: border-box;
      height: 40px;
      border: 1px solid #000000;
      background: #ffaf00;
      box-shadow: 0px 4px 0px 0px #000000;
      border-radius: 8px;
      text-align: center;
      color: #000000;
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

  .pc-scroll-cont {
    padding: 12px 20px;
    font-size: 14px;

    .top-scroll {
      border-right: 3px solid #110b0b75;
      cursor: pointer;

      span {
        margin-right: 5px;
      }

      .top-profile-img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        border-radius: 4px;
        margin-left: 14px;
        margin-right: 9px;
      }
    }
  }

  .wallet-btn {
    border: 1px solid #0c1c23;
    border-radius: 8px;
    padding: 10px 12px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .click-item-box {
    background: white;
    border-radius: 8px;
    border: 1px solid #0c1c23;
    padding: 10px 12px;
    position: absolute;
    z-index: 2;
  }

  .head-container {
    .active-router {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 80%;
        bottom: 22px;
        left: 0;
        height: 3px;
        background: #0c1c23;
      }
    }

    // background: yellow;
    // height: 40px;
    // max-width: 768px;
    // margin: auto;
    .head-cont-pc {
      height: 100%;
      justify-content: space-between;
    }

    padding: 0 240px;
    height: 92px;
    border-bottom: 1px solid #756767;

    .head-list-img {
      img {
        width: 241px;
        height: 48px;
      }
    }

    .head-logo-title {
      // margin-right: 164px;
    }

    .head-item-pc {
      line-height: 92px;
      justify-content: flex-start;
      padding-left: 80px;
      box-sizing: border-box;

      .head-list-item {
        width: 100px;
        text-align: center;
        font-size: 16px;
      }
    }

    .h5-head-cont-h5 {
      display: none;
    }
  }

  .icons-wrapper {
    img {
      margin-right: 12px;
    }
  }
}

/**h5 样式 */
@media screen and (max-width: 1079px) {
.h5-wallet-modal-cont{
  padding: 20px;
  width: 330px !important;
.h5-wallet-head{
  margin-bottom: 28px;
}
.h5-wallet-modal-title{
  font-size: 20px;
  color: #E9EDF3;
}
.h5-wallet-item {
  margin-bottom: 28px !important;
  padding: 17px 20px !important;
}
.h5-wallet-name{
  font-size: 20px !important;
  font-weight: 700 !important;
}
.h5-wallet-modal-footer{
  margin-top: 30px;
  .h5-footer-text-one{
    font-size: 16px;
    color: #E9EDF3 !important;
    margin-bottom: 8px !important;
  }
  .h5-footer-text-two{
    font-size: 14px;
  }
}
.h5-wallet-icon{
  width: 40px;
  height: 40px;
}
}
}
</style>

<style>
.swv-modal-container{
  margin: auto;
}
</style>
