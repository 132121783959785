import { createApp } from "vue";
import { Buffer } from "buffer";
import SolanaWallets from "solana-wallets-vue";
import "solana-wallets-vue/styles.css";
import "./assets/reset.scss";
import "./assets/baseDatePicker.scss";
import App from "./App.vue";
import router from "./router"; // 引入路由
// import "amfe-flexible";
import "./index.scss";
import Vue3SeamlessScroll from "vue3-seamless-scroll";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import { message } from "ant-design-vue";
// import '@/styles/tailwind.css'; // 引入 Tailwind CSS
// 1. 引入你需要的组件
import Vant from "vant";
// 2. 引入组件样式
import "vant/lib/index.css";
import store from "./store";
import VueClipboard from "vue-clipboard3";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";

window.Buffer = Buffer;
message.config({
  top: `30%`,
});
export const getNetwork = () => {
  console.log("current env==>", import.meta.env.DEV);
  if (import.meta.env.DEV) {
    return WalletAdapterNetwork.Devnet;
  } else if (import.meta.env.PROD) {
    return WalletAdapterNetwork.Mainnet;
  } else {
    return WalletAdapterNetwork.Testnet;
  }
};

const walletOptions = {
  wallets: [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
    new SolflareWalletAdapter(),
  ],
  autoConnect: true,
  cluster: getNetwork(),
};
// 根据环境输出不同信息
if (import.meta.env.DEV) {
  console.log("is dev");
}

if (import.meta.env.PROD) {
  console.log("is main");
}

const app = createApp(App);

app.use(Vant);
app.use(Antd);
app.use(store);
app.use(Vue3SeamlessScroll, {
  step: 1,
  limitMoveNum: 5,
  hoverStop: true,
  direction: "vertical",
  openTouch: true,
  singleHeight: 0,
  waitTime: 0,
});
app.use(VueClipboard);
// 注册 vue-router
app.use(router);
// 注册钱包
app.use(SolanaWallets, walletOptions);
// 挂载到 DOM
app.mount("#app");
