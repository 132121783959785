// store/modules/wallet.ts
import { ActionContext, Module } from "vuex";
import { useWallet } from "solana-wallets-vue";
import { sign } from "tweetnacl";
import bs58 from "bs58";
import { watch } from "vue";
import { userLogin } from "@/api";
import { Wallet } from "solana-wallets-vue/dist/types";
import { createMessageBuilders } from "@/utils/messageTemplates";
import { UserState } from "./user";
import { showDialog } from "vant";
import { removeItem, setItem } from "@/utils/storage";

interface WalletState {
  publicKey: string | null;
  connected: boolean;
  loading: boolean;
  error: string | null;
  token: string | null;
  wallet: Wallet | null;
  showLoginModal: boolean;
}

interface RootState {
  wallet: WalletState;
  device: {
    isPc: boolean;
  };
  user: UserState;
}

const wallet: Module<WalletState, RootState> = {
  namespaced: true,

  state: {
    publicKey: null,
    connected: false,
    loading: false,
    error: null,
    token: localStorage.getItem("token") || "",
    wallet: null,
    showLoginModal: false,
  },

  getters: {
    isConnected: (state) => state.connected,
    getPublicKey: (state) => state.publicKey,
    getLoading: (state) => state.loading,
    getError: (state) => state.error,
    getToken: (state) => state.token,
  },

  mutations: {
    SET_SHOW_LOGIN_MODAL(state, show: boolean) {
      state.showLoginModal = show;
    },
    SET_PUBLIC_KEY(state, publicKey: string | null) {
      state.publicKey = publicKey;
    },
    SET_CONNECTED(state, connected: boolean) {
      state.connected = connected;
    },
    SET_LOADING(state, loading: boolean) {
      state.loading = loading;
    },
    SET_ERROR(state, error: string | null) {
      state.error = error;
    },
    SET_TOKEN(state, token: string | null) {
      state.token = token;
      if (token) {
        setItem("token", token);
      } else {
        removeItem("token");
      }
    },
    SET_WALLET(state, wallet: Wallet | null) {
      state.wallet = wallet;
    },
  },

  actions: {
    // 初始化钱包监听
    initWalletListener({ commit, dispatch, getters, rootGetters }) {
      const { publicKey, connected } = useWallet();

      // 监听钱包连接状态
      watch(connected, (newConnected) => {
        commit("SET_CONNECTED", newConnected);
        if (newConnected) {
          //获取用户信息
          const userinfo = rootGetters["user/userInfo"];
          !userinfo && dispatch("signInWithWallet");
        } else {
          dispatch("disconnectWallet");
        }
      });

      // 监听公钥变化
      watch(publicKey, (newPublicKey) => {
        commit("SET_PUBLIC_KEY", newPublicKey?.toBase58() || null);
      });
      watch(wallet, (newWallet) => {
        commit("SET_WALLET", newWallet);
      });
    },
    async switchAccount({ commit }: ActionContext<WalletState, RootState>) {
      const { wallet, disconnect, connect } = useWallet();
      // 使用vant里面的confirm
      showDialog({
        title: "switch account",
        message: "are you sure to switch account?",
        confirmButtonText: "ok",
        cancelButtonText: "cancel",
        width: 300,
        showCancelButton: true,
      })
        .then(async () => {
          // on confirm
          commit("SET_LOADING", true);
          try {
            await disconnect();
            await connect();
            if (wallet.value) {
              const { publicKey } = useWallet();
              commit("SET_PUBLIC_KEY", publicKey.value?.toString() || null);
            }
            commit("SET_CONNECTED", true);
          } catch (error) {
            console.error("切换账户时出错:", error);
            commit("SET_CONNECTED", false);
          } finally {
            commit("SET_LOADING", false);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    // 连接钱包
    async connectWallet({ commit }) {
      const { connect } = useWallet();
      commit("SET_LOADING", true);
      commit("SET_ERROR", null);

      try {
        await connect();
      } catch (error: any) {
        commit("SET_ERROR", error.message);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },

    // 断开钱包连接
    async disconnectWallet({ commit }) {
      const { disconnect } = useWallet();
      commit("SET_LOADING", true);

      try {
        await disconnect();
        commit("SET_PUBLIC_KEY", null);
        commit("SET_CONNECTED", false);
        commit("SET_TOKEN", null);
        commit("user/SET_USER_INFO", null, { root: true });
      } catch (error: any) {
        commit("SET_ERROR", error.message);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },

    // 生成随机nonce
    generateNonce() {
      const random = new Uint8Array(32);
      crypto.getRandomValues(random);
      return bs58.encode(random);
    },
    // prepare msg
    async prepare({ dispatch }) {
      const nonce = await dispatch("generateNonce");
      const statement = createMessageBuilders(
        window.location.host
      ).login.buildLoginMessage({
        statement: "Sign to login to our platform",
        expirationTime: new Date(Date.now() + 30 * 60 * 1000).toISOString(), // 30分钟后过期
      });
      return `${statement}${nonce}`;
    },
    // 验证签名
    verifySignature(_, { message, signature, publicKey }: any) {
      return sign.detached.verify(
        bs58.decode(message),
        bs58.decode(signature),
        bs58.decode(publicKey)
      );
    },

    // 钱包签名登录
    async signInWithWallet({
      state,
      rootState,
      commit,
      dispatch,
    }): Promise<string> {
      const { signMessage } = useWallet();
      commit("SET_LOADING", true);
      commit("SET_ERROR", null);

      try {
        // 检查钱包连接
        if (!state.connected) {
          await dispatch("connectWallet");
        }

        if (!state.publicKey) {
          throw new Error("Wallet not connected");
        }

        const signLoginMessage = async (
          message: Uint8Array
        ): Promise<Uint8Array> => {
          try {
            // 检查钱包连接
            if (!state.connected) {
              throw new Error("Wallet not connected");
            }
            // 使用签名者签名消息
            const signedMessage = signMessage.value
              ? await signMessage.value(message)
              : new Uint8Array();
            return signedMessage;
          } catch (error) {
            console.error("签名消息时出错:", error);
            throw error;
          }
        };
        // 生成消息并签名
        const message = await dispatch("prepare");
        const encodedMessage = new TextEncoder().encode(message);
        const signedMessage = await signLoginMessage(encodedMessage);
        const isPc = rootState.device.isPc;
        const base64Signature = btoa(String.fromCharCode(...signedMessage)); // 将签名转换为 base64 编码
        // 准备登录数据
        // 获取 URL 中的 inviteCode
        const urlParams = new URLSearchParams(window.location.search);
        const inviteCode = urlParams.get("inviteCode") || "";
        const loginData = {
          address: state.publicKey,
          //signature: bs58.encode(signedMessage),
          signature: base64Signature,
          message: bs58.encode(encodedMessage),
          //message: message,
          source: isPc ? "PC" : "H5",
          timestamp: Date.now().toString(),
          userInvitationCode: inviteCode,
        };

        // 发送到后端验证
        const response = await userLogin(loginData);
        if (!response) {
          throw new Error("Login failed");
        }

        const userinfo = response;
        commit("SET_TOKEN", userinfo.token);
        // 设置用户信息
        commit("user/SET_USER_INFO", userinfo, { root: true });
        return userinfo.token;
      } catch (error: any) {
        commit("SET_ERROR", error.message);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
};

export default wallet;
