import { GetterTree, MutationTree } from "vuex";

// src/store/modules/launch.ts

type CommonState = {
  loading: boolean;
};

const state = () => ({
  loading: false,
});

const mutations: MutationTree<CommonState> = {
  SET_LOADING(state, loading: boolean) {
    state.loading = loading;
  },
};

const getters: GetterTree<CommonState, RootState> = {
  getLoading: (state) => state.loading,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
