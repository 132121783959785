import { GetterTree, MutationTree } from "vuex";
import { getSolPriceFromCoinGecko } from "@/api";
type SolPriceState = {
  solPrice: number;
};
const state: SolPriceState = {
  solPrice: 0,
};

const mutations: MutationTree<SolPriceState> = {
  SET_SOL_PRICE(state, price: number) {
    state.solPrice = price;
  },
};

const getters: GetterTree<SolPriceState, RootState> = {
  solPrice: (state) => state.solPrice,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
