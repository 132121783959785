<template>
  <div class="footer-container h5-footer">
    <div class="mb-2">© 2024 Pumpup. All rights reserved</div>
    <div>
      This site is protected by reCAPTCHA and the Google
      <a class="tips h5-tips" href="https://pumpup-ai-1.gitbook.io/pumpup.ai/privacy-policy" target="_blank"> Privacy Policy</a> and
      <a class="tips h5-tips" href="https://pumpup-ai-1.gitbook.io/pumpup.ai/terms-of-use" target="_blank"> Terms of Service</a> apply.
    </div>
  </div>
</template>
<style lang="scss" scoped>
.footer-container {
  width: 100%;
  background: #1f212e;
  padding: 15px 0;
  font-size: 14px;
  color: #6a7a8e;
  text-align: center;
  .tips {
    color: #f5d659;
    font: 500;
    display: inline-block;
  }
}
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 767px) {
  .h5-footer {
    padding: 35px 18px;
    font-size: 14px;
    .h5-tips {
      // color: #6b2323;
      color: #e9edf3;
      font-weight: 500;
      text-decoration: underline;
      display: inline-block;
      line-height: 40px;
    }
  }
}
</style>
