import request from "@/utils/http";

export interface UserInfo {
  address: string;
  avatarUrl: string;
  lastLoginAt: string;
  nickname: string;
  signature: string;
  token: string;
  userInvitationCode: string;
}
type LoginParams = {
  address: string;
  signature: string;
  message?: string;
  source: string;
  timestamp: string;
  userInvitationCode: string;
};

// 请求参数接口
export type MyHoldsParams = {
  condition: string;
  name: string;
  page: number;
  size: number;
  address: string;
};
type MyHolds = {
  date: string;
  invitee: string;
  amount: string;
};

// 上传头像返回值
type UploadAvatar = {
  id: string;
  name: string;
  suffix: string;
  url: string;
};

//编辑用户信息参数
export type EditUserInfoParams = {
  avatarUrlFileNo: number;
  nickname: string;
  signature: string;
};

// 列表项接口
export interface HoldItem {
  avatarUrl: string;
  balance: string;
  createdAt: string;
  isCreate: string;
  name: string;
  ticker: string;
  tokenAddress: string;
  walletAddress: string;
  vol?: number;
  balanceMath: string;
  price: number;
  launchProgress: string;
}

// 分页数据接口
export interface HoldListResponse {
  content: HoldItem[];
  totalElements: number;
  totalPages: number;
}

export const getUserInfo = (address:string) => {
  return request.get("/userInfo/index/" + address) as Promise<UserInfo>;
};

export const userLogin = (data: LoginParams) => {
  return request.post("/user/login", data) as Promise<UserInfo>;
};

export const userLoginOut = () => {
  return request.post("/user/loginOut") as Promise<any>;
};
// 我的持仓
export const getMyHolds = (params: MyHoldsParams) => {
  return request.post("/userInfo/myHolds", params) as Promise<HoldListResponse>;
};
// 我的创建
export const getMyCreated = (params: MyHoldsParams) => {
  return request.post("/userInfo/myCreate", params) as Promise<HoldListResponse>;
};
// 上传头像
export const uploadAvatar = (params: FormData) => {
  return request.post("/file/uploadFile", params) as Promise<UploadAvatar>;
};
//编辑用户信息
export const editUserInfo = (params: EditUserInfoParams) => {
  return request.post("/userInfo/update", params) as Promise<any>;
};
