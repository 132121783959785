
// 设置项
export function setItem(key: string, value: any): void {
  localStorage.setItem(key, JSON.stringify(value));
}

// 获取项
export function getItem<T>(key: string): T | null {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) as T : null;
}

// 删除项
export function removeItem(key: string): void {
  localStorage.removeItem(key);
}

// 清除所有项
export function clear(): void {
  localStorage.clear();
}
