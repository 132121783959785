<template>
  <PcCookieModal v-if="isPc"></PcCookieModal>
  <H5CookieModal v-else></H5CookieModal>
</template>
<script setup lang="ts">
import PcCookieModal from "./PcCookieModal.vue";
import H5CookieModal from "./H5CookieModal.vue";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const isPc = computed(() => {
  console.log("store.getters._isPc@@@@@@@");
  return store.getters["device/_isPc"];
});
</script>
<style lang="scss" scoped>
// @use "./index.scss";
</style>
