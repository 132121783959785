import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { path: "/", component: () => import("@/pages/Home/index.vue") },
  { path: "/launch", component: () => import("@/pages/Launch/index.vue") },
  { path: "/ranking", component: () => import("@/pages/rank/index.vue") },
  { path: "/invite", component: () => import("@/pages/invite/index.vue") },
  { path: "/details/:id", component: () => import("@/pages/details/index.vue") },
  { path: "/profile", component: () => import("@/pages/Profile/index.vue") },
  { path: "/aiPool", component: () => import("@/pages/aiPool/index.vue") },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
