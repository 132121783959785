import Axios, { AxiosInstance, CreateAxiosDefaults } from "axios";
import "vant/es/toast/style";
import { getItem, removeItem } from "@/utils/storage";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { showToast } from "vant";
import successIcon from '@/assets/success.svg';
import errorIcon from '@/assets/error.svg';

// 默认 axios 实例请求配置
const configDefault = {
  headers: {
    // json 格式
    "Content-Type": "application/json;charset=UTF-8",
  },
  timeout: 30 * 1000,
  baseURL: "/api",
  data: {},
};

class Http {
  private static router = useRouter();
  private static store = useStore();

  constructor(config: CreateAxiosDefaults) {
    Http.axiosConfigDefault = config;
    Http.axiosInstance = Axios.create(config);
    this.httpInterceptorsRequest();
    this.httpInterceptorsResponse();
  }
  // 当前实例
  static axiosInstance: AxiosInstance;
  // 请求配置
  static axiosConfigDefault: CreateAxiosDefaults;

  // 请求拦截
  httpInterceptorsRequest() {
    Http.axiosInstance.interceptors.request.use(
      (config) => {
        // 发送请求前，可在此携带 token
        const token = getItem("token");
        const deviceCode = getItem("devicecode");
        // 如果是 FormData 类型，不设置 Content-Type，让浏览器自动设置带 boundary 的格式
        if (config.data instanceof FormData) {
          config.headers["Content-Type"] = "multipart/form-data";
          // 或者直接删除 Content-Type，让浏览器自动设置
          // delete config.headers['Content-Type']
        } else {
          config.headers["Content-Type"] = "application/json";
        }
        if (token) {
          config.headers["Auth-Token"] = token;
        }
        if (deviceCode) {
          config.headers["Device-Code"] = deviceCode;
        }
        return config;
      },
      (error) => {
        showToast({
          wordBreak: "break-word",
          message: error.message,
          icon: errorIcon
        })
        return Promise.reject(error);
      }
    );
  }

  // 响应拦截
  httpInterceptorsResponse() {
    Http.axiosInstance.interceptors.response.use(
      (response) => {
        // 与后端协定的返回字段
        const { code, msg, data, locale = "en_US" } = response.data;
        // 判断请求是否成功 （code 200 请求成功）
        const isSuccess =
          Reflect.has(response.data, "code") && code === "00000";
        if (isSuccess) {
          return data;
        } else {
          // 处理 BB003 错误码
          if (code === "BB003" || code === "BB004") {
            // 退出登录
            Http.store && Http.store.dispatch("wallet/disconnectWallet");
            // Http.store.dispatch("wallet/disconnectWallet");

            // 显示提示
            // const message = "Login expired, please login again later";
            // showFailToast({
            //   message,
            //   duration: 2000,
            //   onClose: () => {
            //     //打开钱包登录窗口
            //   },
            // });
            return Promise.reject(response.data);
          }
          // 处理请求错误
          showToast({
            wordBreak: "break-word",
            message: msg,
            icon: errorIcon
          })
          return Promise.reject(response.data);
        }
      },
      (error) => {
        // 处理 HTTP 网络错误
        let message = "";
        // HTTP 状态码
        const status = error.response?.status;
        switch (status) {
          case 400:
            message = "Request error";
            break;
          case 401:
            message = "Not authorized, please log in";
            break;
          case 403:
            message = "Access denied";
            break;
          case 404:
            message = `Request address error: ${error.response?.config?.url}`;
            break;
          case 408:
            message = "Request timeout";
            break;
          case 500:
            message = "Server internal error";
            break;
          case 501:
            message = "Service not implemented";
            break;
          case 502:
            message = "Gateway error";
            break;
          case 503:
            message = "Service unavailable";
            break;
          case 504:
            message = "Gateway timeout";
            break;
          case 505:
            message = "HTTP versions are not supported";
            break;
          default:
            message = "Network connection failure";
        }

        showToast({
          wordBreak: "break-word",
          message: message,
          icon: errorIcon
        })
        return Promise.reject(error);
      }
    );
  }

  // 通用请求函数
  request(paramConfig: any) {
    const config = { ...Http.axiosConfigDefault, ...paramConfig };
    return new Promise((resolve, reject) => {
      Http.axiosInstance
        .request(config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

const http = new Http(configDefault);

const request = {
  get: (url: string, data = {}, config = {}) => {
    return http.request({
      url,
      method: "get",
      params: data,
      ...config,
    });
  },
  post: (url: string, data = {}, config = {}) => {
    return http.request({
      url,
      method: "post",
      data,
      ...config,
    });
  },
  put: (url: string, data = {}, config = {}) => {
    return http.request({
      url,
      method: "put",
      data,
      ...config,
    });
  },
  delete: (url: string, data = {}, config = {}) => {
    return http.request({
      url,
      method: "delete",
      data,
      ...config,
    });
  },
};

export default request;
