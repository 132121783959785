<template>
  <div class="shake-wrapper h5-shake-wrapper">
    <div
      v-for="card in bounceCards"
      @click="() => handleCardClick(card.data.mint)"
    >
      <BounceCard :key="card.timestamp" :currentItem="card.data" />
    </div>
  </div>
  <div class="head-container lg:px-10 w-full">
    <div class="head-cont-pc flex items-center">
      <div class="head-list-img flex items-center">
        <img
          class="max-w-[200px]"
          src="@/assets/headLogo-pc.svg"
          alt=""
          @click="$router.push('/')"
        />
        <!-- <img style="width:100px;height:22px; margin-left: 16px;" src="@/assets/header/title.png" alt="" /> -->
        <!-- <span class="head-logo-title">Pumpup</span> -->

        <div></div>
      </div>
      <ul
        class="flex items-center head-item-pc"
        style="height: 100%; flex: 1; width: 0"
      >
        <li
          class="head-list-item flex items-center"
          :class="{ 'active-router': route.path === item.url }"
          v-for="item in navList"
          :key="item.title"
        >
          <router-link
            :to="item.url"
            class="transition-colors duration-300 hover:text-[#fcd535]"
          >
            {{ item.title }}
          </router-link>
        </li>
      </ul>

      <div class="flex items-center space-x-8 mr-8 cursor-pointer">
        <div class="p-[8px] bg-[#1F212C] hover:bg-[#2a2d3d] transition-colors duration-300 rounded-md" v-for="icon in headerIcons">
          <img
            :key="icon.name"
            :src="icon.icon"
            :alt="icon.name"
            @click="handleIconClick(icon.link)"
          />
        </div>
      </div>
      <!-- <wallet-multi-button></wallet-multi-button> -->

      <!-- 自定义按钮 这些方法都可以使用：const scope = {
        openModal,
        featured,
        container,
        logo,
        dark,
        wallet,
        publicKey,
        publicKeyTrimmed,
        publicKeyBase58,
        canCopy,
        addressCopied,
        dropdownPanel,
        dropdownOpened,
        openDropdown,
        closeDropdown,
        copyAddress,
        disconnect,
      };-->
      <Walletlogin></Walletlogin>
    </div>
    <div class="h5-head-cont-h5" @click="toggleDropDown">
      <div class="flex justify-between items-center h5-head-item">
        <div class="h5-head-icon-h5">
          <img
            src="@/assets/headLogo-pc.svg"
            alt=""
            @click="$router.push('/')"
          />
        </div>
        <div class="h5-head-title">
          <WalletButtonModal v-if="!address">
            <template v-slot:default="scope">
              <div
                class="h5-wallet-btn h5-wallet-btn-login"
                @click="scope.openModal()"
              >
                wallet login
              </div>
            </template>
          </WalletButtonModal>
          <img
            @click.stop="itemRef && itemRef.toggle()"
            src="@/assets/header/menu.svg"
            style="margin-left: 8px"
          />
          <van-dropdown-menu
            ref="dropDownRef"
            class="h5-menu-item"
            :close-on-click-outside="false"
          >
            <van-dropdown-item ref="itemRef">
              <van-cell
                @click="handelDropdown(item)"
                v-for="(item, index) in optionList"
                :key="index"
              >
                <template #title>
                  <div
                    class="h5-menu-item-list flex items-center"
                    v-if="index !== 5"
                  >
                    <!-- <img style="margin-right: 8px" :src="item.icon" /> -->
                    <span class="h5-menu-item-text">{{ item.text }}</span>
                  </div>
                  <WalletButtonModal v-else>
                    <template v-slot:default="scope">
                      <div
                        class="h5-menu-item-list flex items-center"
                        @click="openIt(scope)"
                      >
                        <img style="margin-right: 8px" :src="item.icon" />
                        <span class="h5-menu-item-text">{{ item.text }}</span>
                      </div>
                    </template>
                  </WalletButtonModal>
                </template>
                <template v-if="item.value === 6 && address" #right-icon>
                  <WalletButtonModal>
                    <template v-slot:default="scope">
                      <div class="flex items-center">
                        <img
                          style="width: 16px; height: 16px"
                          src="@/assets/launch/currentIcon.png"
                          alt=""
                        />
                        <div
                          style="
                            color: #adb8c9;
                            font-size: 16px;
                            margin-left: 8px;
                          "
                          v-if="address"
                        >
                          {{ getSubStr(address) }}
                        </div>
                      </div>
                    </template>
                  </WalletButtonModal>
                </template>
              </van-cell>
              <div class="h5-disconnet-btn" v-if="address">
                <WalletButtonModal>
                  <template v-slot:default="scope">
                    <div
                      class="h5-wallet-btn h5-wallet-btn-big"
                      @click="scope.disconnect()"
                    >
                      Disconnect
                    </div>
                  </template>
                  <!-- <template v-slot:modal="scope">
                    
                  </template> -->
                </WalletButtonModal>
              </div>
            </van-dropdown-item>
          </van-dropdown-menu>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch, onMounted, onUnmounted } from "vue";
import { useBounceCardData } from "./hooks";
import { useRouter, useRoute } from "vue-router";

import { getSubStr } from "@/utils/common";
import Walletlogin from "@/components/WalletLogin.vue";
import WalletButtonModal from "@/components/WalletButtonModal.vue";
import BounceCard from "./components/BounceCard.vue";
import { useStore } from "vuex";
const router = useRouter();
const route = useRoute();
import type { Ref } from "vue";
import type { DropdownItemInstance } from "vant";

const { bounceCards, receiveData } = useBounceCardData();
const store = useStore();
const bounceData = computed(() => store.getters["ws/getBounceData"]);
const handleCardClick = (mint: any) => {
  if (!mint) {
    return;
  }
  router.push(`/details/${mint}`);
};
// 监听props变化，将新数据添加到队列
watch(
  () => bounceData.value,
  (newData) => {
    receiveData(newData);
  }
);
const toggleDropDown = () => {
  dropDownRef.value && dropDownRef.value.close();
};
const dropDownRef = ref(null);
const itemRef: Ref<DropdownItemInstance | null> = ref(null);
// 头部图标
const headerIcons = [
  {
    name: "plate",
    link: "https://pumpup-1.gitbook.io/pumpup",
    icon: new URL("@/assets/header/plate.svg", import.meta.url).href,
  },
  {
    name: "telegram",
    link: "https://t.me/Pumpup_Ai_channel",
    icon: new URL("@/assets/header/telegram.svg", import.meta.url).href,
  },
  {
    name: "x",
    link: "https://x.com/Pumpup_ai",
    icon: new URL("@/assets/header/twitter.svg", import.meta.url).href,
  },
  // {
  //   name: "note",
  //   link: "https://note-link.com",
  //   icon: new URL("@/assets/header/note.svg", import.meta.url).href,
  // },
];
const handleIconClick = (link: string) => {
  window.open(link, "_blank");
};

const showText = ref<boolean>(true);
const address = computed(() => store.getters["wallet/getPublicKey"]);
const openIt = (scope: any) => {
  if (!address.value) {
    scope.openModal();
  }
};
const optionList = ref([
  {
    text: "Home",
    value: 1,
    url: "/",
    icon: new URL("@/assets/header/h5-home-icon.svg", import.meta.url).href,
  },
  {
    text: "Launch",
    value: 2,
    url: "/launch",
    icon: new URL("@/assets/header/h5-launch-icon.svg", import.meta.url).href,
  },
  {
    text: "AI Pool",
    value: 3,
    url: "/aiPool",
    icon: new URL("@/assets/header/h5-aiPool-icon.svg", import.meta.url).href,
  },
  //暂时不上隐藏
  // {
  //   text: "Ranking",
  //   value: 4,
  //   url: "/ranking",
  //   icon: new URL("@/assets/header/h5-rank-icon.svg", import.meta.url).href,
  // },
  {
    text: "Invite",
    value: 5,
    url: "/invite",
    icon: new URL("@/assets/header/h5-invite-icon.svg", import.meta.url).href,
  },
  {
    text: "Profile",
    value: 6,
    url: "/profile",
    icon: new URL("@/assets/header/h5-profile-icon.svg", import.meta.url).href,
  },
]);

const navList = [
  {
    title: "Home",
    url: "/",
    icon: new URL("@/assets/header/home.png", import.meta.url).href,
  },
  {
    title: "Launch",
    url: "/launch",
    icon: new URL("@/assets/header/launch.png", import.meta.url).href,
  },
  {
    title: "AI Pool",
    url: "/aiPool",
    icon: new URL("@/assets/header/ai.png", import.meta.url).href,
  },
  //暂时不上隐藏
  // {
  //   title: "Ranking",
  //   url: "/ranking",
  //   icon: new URL("@/assets/header/rank.png", import.meta.url).href,
  // },
  {
    title: "Invite",
    url: "/invite",
    icon: new URL("@/assets/header/invite.png", import.meta.url).href,
  },
  // {
  //   title: "Profile",
  //   url: "/profile",
  //   icon: new URL('@/assets/header/invite.png', import.meta.url).href
  // },
];

function handelDropdown(value: any) {
  showText.value = false;
  router.push(
    value.value === 6 ? value.url + "?address=" + address.value : value.url
  );
  // 根据选中的 value 查找对应的对象
  // const selectedOption = optionList.value.find(
  //   (option) => option.value === value
  // );
  // if (selectedOption && selectedOption.url) {
  //   router.push(selectedOption.url);
  // }
}
// function handleAddress(){
// showWalletModal.value = true;
// }

function toHandlerKey(item: any) {
  console.log(item);
}
</script>

<style lang="scss" scoped>
$theme-bg-color: #181a20;
:deep(.van-popover__content) {
  background: #d8be80 !important;
}

:deep(.van-popover__action-text) {
  color: #0c1c23 !important;
}

.block {
  color: #fffb24;
}

.pc-scroll-cont {
  background: #7b652f;
  color: #110b0b;
}

.wallet-icon {
  width: 40px;
  height: 40px;
  border-radius: 25px;
}

/* PC样式 and 平板设备样式 */
@media screen and (min-width: 1080px) {
  .wallet-modal-cont-pc {
    padding: 40px;
    width: 634px;
    .wallet-head-pc {
      margin-bottom: 48px;
      .wallet-modal-title-pc {
        color: #0c1c23;
        font-size: 20px;
      }
    }
  }
  .shake-wrapper {
    width: 100%;
    height: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1b110b;
    color: #110b0b;
    .left {
      background-color: #31c01c;
    }
    .right {
      background-color: #7b652f;
    }
    .shake-lr {
      display: flex;
      align-items: center;
      padding: 2px 5px;
      margin-right: 10px;
      // width: 200px;
      height: 30px;

      animation: shake-lr 1s both;
      // animation-iteration-count: infinite;
      .avatar {
        height: 15px;
        width: 15px;
        margin-right: 4px;
      }
      .text {
        font-size: 14px;
        color: #110b0b;
      }
      .bought {
        color: yellow;
        font-size: 14px;
        margin: 0 4px;
      }
      .sol {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-left: 4px;
      }
    }
  }
  .pc-scroll-cont {
    padding: 12px 20px;
    font-size: 14px;

    .top-scroll {
      border-right: 3px solid #110b0b75;
      cursor: pointer;

      span {
        margin-right: 5px;
      }

      .top-profile-img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        border-radius: 4px;
        margin-left: 14px;
        margin-right: 9px;
      }
    }
  }

  .wallet-btn {
    // border: 2px solid #0c1c23;
    border-radius: 8px;
    padding: 10px 12px;
    text-align: center;
    background: #fcd535;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .click-item-box {
    background: white;
    border-radius: 8px;
    border: 2px solid #0c1c23;
    padding: 10px 12px;
    position: absolute;
    z-index: 2;
  }

  .head-container {
    height: 60px;
    border-bottom: 1px solid #2b3139;
    background-color: $theme-bg-color;
    .active-router {
      position: relative;
      .router-link-active {
        color: white;
        text-transform: uppercase;
      }
      &::after {
        content: "";
        position: absolute;
        width: 80%;
        bottom: 22px;
        left: 50%;
        transform: translateX(-50%);
        height: 3px;
        background: #fcd535;
        color: white;
      }
    }

    // background: yellow;
    // height: 40px;
    // max-width: 768px;
    // margin: auto;
    .head-cont-pc {
      height: 100%;
      justify-content: space-between;
      background-color: $theme-bg-color;
    }

    .head-list-img {
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        width: 241px;
        height: 48px;
      }
    }

    .head-logo-title {
      // margin-right: 164px;
    }

    .head-item-pc {
      line-height: 92px;
      justify-content: center;
      padding-left: 80px;
      box-sizing: border-box;

      .head-list-item {
        // width: 100px;
        padding: 0 30px;
        text-align: center;
        font-size: 20px;
        a {
          transition: color 0.3s;
          color: #adb8c9;
          &:hover {
            color: #ffffff !important; /* 添加 !important 来确保优先级 */
          }
        }
      }
      .active-router {
        a {
          color: #fff;
        }
      }
    }

    .h5-head-cont-h5 {
      display: none;
    }
  }
}

/* 移动设备样式 (H5) */
@media screen and (max-width: 1079px) {
  // :deep(.van-dropdown-menu__title:after){
  //   opacity: 0 !important;
  // }
  .h5-shake-wrapper {
    display: none;
    // height: 50px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // background: #1b110b;
    // color: #110b0b;
    // .h5-left {
    //   background-color: #31c01c;
    // }
    // .h5-right {
    //   background-color: #7b652f;
    // }
    // .h5-shake-lr {
    //   display: flex;
    //   align-items: center;
    //   padding: 2px 5px;
    //   margin-right: 10px;
    //   // width: 200px;
    //   height: 30px;

    //   animation: shake-lr 0.7s both;
    //   animation-iteration-count: infinite;
    //   .h5-avatar {
    //     // height: 15px;
    //     width: 15px;
    //     margin-right: 4px;
    //   }
    //   .h5-text {
    //     font-size: 14px;
    //     color: #110b0b;
    //     white-space: nowrap;
    //     overflow: hidden; /* 隐藏溢出内容 */
    //     text-overflow: ellipsis; /* 使用省略号显示溢出文本 */
    //   }
    //   .h5-bought {
    //     color: yellow;
    //     font-size: 14px;
    //     margin: 0 4px;
    //   }
    //   .h5-sol {
    //     // width: 15px;
    //     // height: 15px;
    //     border-radius: 50%;
    //     margin-left: 4px;
    //   }
    // }
    // @keyframes h5-shake-lr {
    //   0% {
    //     transform: translateX(-48px);
    //     animation-timing-function: ease-in;
    //     opacity: 1;
    //   }
    //   24% {
    //     opacity: 1;
    //   }
    //   40% {
    //     transform: translateX(-26px);
    //     animation-timing-function: ease-in;
    //   }
    //   65% {
    //     transform: translateX(-13px);
    //     animation-timing-function: ease-in;
    //   }
    //   82% {
    //     transform: translateX(-6.5px);
    //     animation-timing-function: ease-in;
    //   }
    //   93% {
    //     transform: translateX(-4px);
    //     animation-timing-function: ease-in;
    //   }
    //   25%,
    //   55%,
    //   75%,
    //   87%,
    //   98% {
    //     transform: translateX(0);
    //     animation-timing-function: ease-out;
    //   }
    //   100% {
    //     transform: translateX(0);
    //     animation-timing-function: ease-out;
    //     opacity: 1;
    //   }
    // }
  }
  .h5-wallet-btn-big {
    height: 44px !important;
    line-height: 44px !important;
    width: 360px !important;
    border-radius: 8px !important;
    text-align: center;
  }
  .h5-menu-item {
    :deep(.van-dropdown-item) {
      // top: 105px !important;
    }
    .h5-menu-item-list {
      line-height: 38px;
      img {
        width: 20px;
        height: 20px;
      }
      .h5-menu-item-text {
        font-size: 16px;
        color: #fff;
        font-weight: 400;
        display: inline-block;
      }
    }
  }

  .h5-wallet-btn {
    border: 2px solid #36404e;
    border-radius: 8px;
    height: 32px;
    line-height: 32px;
    color: #adb8c9;
    font-size: 16px;
    padding: 0 12px;
    font-weight: 600;
  }
  .h5-wallet-btn-login {
    background: #fcd535;
    border: none;
    color: #1e1e1e;
  }

  .h5-scroll-cont {
    padding: 12px 20px;
  }

  .head-cont-pc {
    display: none;
  }

  :deep(.van-dropdown-menu__bar) {
    background: none;
    box-shadow: none;
  }

  .h5-head-cont-h5 {
    border-bottom: 1px solid #3c3c32;

    .h5-head-title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 12px 0;
      // width: 80px;
      font-size: 16px;
    }
  }

  .h5-top-profile-img {
    width: 24px;
    height: 24px;
    margin: 0 12px;
  }

  .h5-home-top-tex {
    font-size: 14px;
    margin: 0 4px;
  }

  .h5-price {
    padding-right: 10px;
    border-right: 2px solid #110b0b75;
    display: inline-block;
  }
}

:deep(.van-haptics-feedback) {
  display: none;
}

.h5-menu-item {
  :deep(.van-overlay) {
    background-color: #0c0e1b99;
    filter: blur(1);
  }
  :deep(.van-popup) {
    padding-top: 28px;
    background: #15161a !important;
    overflow-y: auto;
    height: auto !important;
    padding-bottom: 120px;
    box-sizing: border-box;
  }

  :deep(.van-cell) {
    background: #15161a !important;
    align-items: center;
  }
  :deep(.van-cell:after) {
    border-bottom: 0 !important;
  }
}

.h5-disconnet-btn {
  display: flex;
  padding-top: 40px;
  box-sizing: border-box;
  align-content: center;
  justify-content: center;
}

.h5-head-item {
  padding: 0 16px;
  box-sizing: border-box;
  background-color: $theme-bg-color;
  color: #fff;
}
</style>
