<template>
    <wallet-multi-button>
        <template v-slot:default="scope">
            <div class="wallet-btn" v-if="!userInfo?.address" @click="scope.openModal()">wallet login</div>
            <van-popover duration="0" :overlay-style="{'opacity':0 , background: 'transparent'}" overlay v-model:show="showWalletModal" v-else :actions='actions' @select="onSelect($event, scope,userInfo?.address)">
                <template #reference>
                    <div class="wallet-address-btn cursor-pointer">{{ getSubStr(userInfo?.address) }}</div>
                </template>
            </van-popover>
            <!-- <div class="wallet-btn" v-else @click="handleAddress">{{ getSubStr(walletAddress) }}</div>
           <div v-show="showWalletModal" class="click-item-box">
            <div>profile</div> 
            <div @click="scope.copyAddress()">copyAddress</div>
            <div @click="scope.openModal()">changeAddress</div>
            <div @click="scope.disconnect()">disconnect</div>
          </div> -->
            <!-- <van-popover v-model:show="showWalletModal" >
            <div>1111</div>
            <div>2222</div>
            <template #reference >
              <div  v-if="walletAddress" class="wallet-btn">
              {{ getSubStr(walletAddress) }}
            </div>
            </template>
          </van-popover> -->
        </template>
        <template v-slot:modal="scope">
            <div class="wallet-modal-cont h5-wallet-modal-cont wallet-modal-cont-pc" style="z-index: 2;">
                <div class="wallet-head wallet-head-pc h5-wallet-head flex items-center justify-between">
                    <div class="wallet-modal-title h5-wallet-modal-title wallet-modal-title-pc">Connect your wallet
                    </div>
                    <div class="wallet-modal-close h5-wallet-modal-close" @click="scope.closeModal()">
                        <img src="@/assets/close.svg" alt="">
                    </div>
                </div>
                <div class="wallet-modal-body h5-wallet-modal-body">
                    <div class="wallet-item flex items-center justify-between"
                        v-for="(item, index) in scope.walletsToDisplay.value.concat(scope.hiddenWallets.value)"
                        :key="index" @click="
                            scope.selectWallet(item.adapter.name);
                        scope.closeModal();
                        ">
                        <div class="wallet-name h5-wallet-name">{{ item.adapter.name }}</div>
                        <img class="wallet-icon h5-wallet-icon" :src="item.adapter.icon" alt="">
                        <!-- <div v-if="item.readyState === 'Installed'">Detected</div> -->
                    </div>
                </div>
                <div class="wallet-modal-footer h5-wallet-modal-footer">
                    <span class="footer-text-one h5-footer-text-one">By selecting Connect,you have accepted</span>
                    <span class="footer-text-two h5-footer-text-two">Terms of Service, Privacy Policy</span>
                </div>
            </div>
        </template>
    </wallet-multi-button>
</template>

<script lang="ts" setup>
import { WalletMultiButton } from "solana-wallets-vue";
import { getSubStr } from '@/utils/common';
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { UserInfo } from "@/api";
import { showToast } from "vant";
import { useRouter } from "vue-router";
import successIcon from '@/assets/success.svg';
const store = useStore()
const isPc = computed(() => {
    return store.getters["device/_isPc"]
})
const showWalletModal = ref(false);
// 计算属性控制弹窗显示
const showLoginModal = computed({
  get: () => store.state.wallet.showLoginModal,
  set: (value) => store.commit('wallet/SET_SHOW_LOGIN_MODAL', value)
});
// 关闭弹窗
const handleClose = () => {
  store.commit('wallet/SET_SHOW_LOGIN_MODAL', false);
};
const userInfo = computed<UserInfo>(() => store.getters['user/userInfo'])
const connected = computed(() => store.getters['wallet/connected'])
const actions = ref([
    { text: 'Profile' },
    // { text: 'copyAddress' },
    // { text: 'changeAddress' },
    { text: 'Disconnect' },
])
const router = useRouter()
const onSelect = ({ text }: any, scope: any,address) => {
    switch (text) {
        case 'Profile':
            router.push('/profile?address=' + address);
            break;
        case 'copyAddress':
            scope.copyAddress();
            showToast({
                message: `Success!`,
                icon: successIcon,
            })
            break;
        case 'changeAddress':
            store.dispatch('wallet/switchAccount');
            break;
        case 'Disconnect':
            store.dispatch('wallet/disconnectWallet');
            break;
        default:
            break;
    }
}
</script>

<style scoped lang="scss">
:deep(.van-dropdown-menu__bar) {
    background: none;
    box-shadow: none;
}

:deep(.van-popover__content) {
    background: #282E36 !important;
    .van-popover__action-text {
        color: #ADB8C9;
        font-size: 16px;
        font-weight: 600;
    }
    .van-popover__action:hover{
        background: #333B46;
        border-radius: 8px;
    }
}

:deep(.van-popover__arrow){
display: none;
}
:deep(.van-hairline--bottom:after){
    border: none;
}

:deep(.van-haptics-feedback) {
    display: none;
}

:deep(.van-popup) {
    background: #D8BE80 !important;
    overflow-y: auto;
    height: auto !important;
    padding-bottom: 120px;
    box-sizing: border-box;
}

:deep(.van-cell) {
    background: #D8BE80 !important;
}

.wallet-icon {
    width: 40px;
    height: 40px;
    border-radius: 25px;
}

.wallet-modal-cont {
    background: #252B34;
    // border: 4px solid #591C02;
    border-radius: 10px;

    .wallet-head {
        color: #0C1C23;
    }

    .wallet-item {
        background: #1D2028;
        padding: 10px 18px;
        margin-bottom: 20px;
        border-radius: 5px;

        .wallet-name {
            color: #757F8D;
            font-size: 19px;
            font-weight: 700;
        }
    }

    .wallet-modal-footer {
        // width: 90%;
        // margin: auto;
        text-align: center;

        span {
            display: block;
        }

        .footer-text-one {
            color: #E9EDF3;
            margin-bottom: 8px;
            font-size: 16px;
        }
        .footer-text-two {
            color: #FCD535;
            font-size: 14px;
        }
    }
}


/* PC样式 and 平板设备样式 */
@media screen and (min-width: 1080px) {
    .wallet-modal-cont-pc {
        padding: 20px;
        width:500px;

        .wallet-head-pc {
            margin-bottom: 20px;

            .wallet-modal-title-pc {
                color: #E9EDF3;
                font-size: 20px;
                font-weight: 400;
            }
        }

    }

    .pc-scroll-cont {
        padding: 12px 20px;
        font-size: 14px;

        .top-scroll {
            border-right: 3px solid #110B0B75;
            cursor: pointer;

            span {
                margin-right: 5px
            }

            .top-profile-img {
                width: 24px;
                height: 24px;
                object-fit: contain;
                border-radius: 4px;
                margin-left: 14px;
                margin-right: 9px;
            }
        }

    }

    .wallet-btn {
        // border: 2px solid #0C1C23;
        border-radius: 8px;
        padding: 10px 12px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        background: #FCD535;
        cursor: pointer;
    }
    .wallet-address-btn{
        background: #1F2128;
        padding: 13px 12px;
        border-radius: 8px;
        border: 1px solid #848E9C99;
        color: #FFFFFFCC;
        font-size: 16px;
        font-weight: 600;
    }

    .click-item-box {
        background: white;
        border-radius: 8px;
        border: 1px solid #0C1C23;
        padding: 10px 12px;
        position: absolute;
        z-index: 2;
    }

    .head-container {
        .active-router {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 80%;
                bottom: 22px;
                left: 0;
                height: 3px;
                background: #0C1C23;
            }
        }

        // background: yellow;
        // height: 40px;
        // max-width: 768px;
        // margin: auto;
        .head-cont-pc {
            height: 100%;
            justify-content: space-between;
        }

        padding: 0 240px;
        height: 92px;
        border-bottom: 1px solid #756767;

        .head-list-img {
            img {
                width: 241px;
                height: 48px;
            }
        }

        .head-logo-title {
            // margin-right: 164px;
        }

        .head-item-pc {
            line-height: 92px;
            justify-content: flex-start;
            padding-left: 80px;
            box-sizing: border-box;

            .head-list-item {
                width: 100px;
                text-align: center;
                font-size: 16px;
            }
        }

        .h5-head-cont-h5 {
            display: none;
        }
    }

    .icons-wrapper {
        img {
            margin-right: 12px;
        }
    }
}
@media screen and (max-width: 1079px){
.h5-wallet-modal-cont{
  padding: 20px;
  width: 330px !important;
.h5-wallet-head{
  margin-bottom: 28px;
}
.h5-wallet-modal-title{
  font-size: 20px;
  color: #E9EDF3;
}
.h5-wallet-item {
  margin-bottom: 28px !important;
  padding: 17px 20px !important;
}
.h5-wallet-name{
  font-size: 20px !important;
  font-weight: 700 !important;
}
.h5-wallet-modal-footer{
  margin-top: 30px;
  .h5-footer-text-one{
    font-size: 16px;
    color: #E9EDF3 !important;
    margin-bottom: 8px !important;
  }
  .h5-footer-text-two{
    font-size: 14px;
  }
}
.h5-wallet-icon{
  width: 40px;
  height: 40px;
}
}
}
</style>