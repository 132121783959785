<template>
  <div v-if="isBlocked" class="overlay">
    <div class="floating-content">
      <div class="title">Cookie settings</div>
      <div class="content">
        We use cookies to provide you with the best possible experiendce. They
        also allow us to analyze user behavior in order to constantly improve
        the website for you
      </div>
      <div class="buttons" @click="handleConfirm">
        <div class="button-yellow">Accept all</div>
        <div class="button-green">Accept Selection</div>
        <div class="button-green">Reject all</div>
      </div>
      <div class="select">
        <div class="checkbox-item">
          <label class="custom-checkbox">
            <input type="checkbox" checked />
            <span class="checkmark"></span>
            Necessary
          </label>
        </div>
        <div class="checkbox-item">
          <label class="custom-checkbox">
            <input type="checkbox" />
            <span class="checkmark"></span>
            Analytics
          </label>
        </div>
        <div class="checkbox-item">
          <label class="custom-checkbox">
            <input type="checkbox" />
            <span class="checkmark"></span>
            Preferences
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
const isBlocked = ref(true);
const COOKIE_CONSENT_KEY = "cookie_consent";
// 检查是否已经同意过 cookie 政策
onMounted(() => {
  const hasConsent = localStorage.getItem(COOKIE_CONSENT_KEY);
  if (hasConsent) {
    isBlocked.value = false;
  }
});
const handleConfirm = () => {
  localStorage.setItem(COOKIE_CONSENT_KEY, "true");
  isBlocked.value = false;
};
</script>
<style scoped lang="scss">
/* 悬浮层覆盖整个页面 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: flex-end; /* 底部悬浮 */
  z-index: 9999;
}

/* 底部悬浮内容 */
.floating-content {
  background-color: #252B34;
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: around;
  align-items: center;
  .title {
    width: 100%;
    height: 20px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #ADB8C9;
  }
  .content {
    width: 100%;
    height: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #757F8D;
  }
  .buttons {
    margin-top: 10px;
    width: 382px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
    .button-yellow {
      width: 120px;
      height: 35px;
      background-color: rgba(252, 213, 53, 1);
      border-radius: 4px;
      text-align: center;
      line-height: 35px;
    }
    .button-green {
      width: 120px;
      height: 35px;
      background-color: #354251;
      border-radius: 4px;
      text-align: center;
      line-height: 35px;
      color: #ADB8C9;
    }
  }
  .select {
    margin-top: 10px;
    width: 332px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #ADB8C9;
    .custom-checkbox {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      font-size: 14px;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: transparent;
        border: 1px solid #ADB8C9;
        border-radius: 3px;
      }

      &:hover input:not(:disabled) ~ .checkmark {
        border-color: rgba(252, 213, 53, 1);
      }

      input:checked ~ .checkmark {
        background-color: rgba(252, 213, 53, 1);
        border-color: rgba(252, 213, 53, 1);
      }

      input:disabled ~ .checkmark {
        opacity: 0.6;
        cursor: not-allowed;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 4px;
        top: 1px;
        width: 5px;
        height: 9px;
        border: solid white;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
      }

      input:checked ~ .checkmark:after {
        display: block;
      }
    }
  }
}
.floating-content .select .custom-checkbox input:checked ~ .checkmark[data-v-9a6f2784]:after {
  border-color: #252b34;
}
</style>
