import { GetterTree, MutationTree } from "vuex";

// src/store/modules/launch.ts

type WsState = {
  tokenData: any;
  leverageData: any;
  bubbleData: any;
  winData: any;
  volumeData: any;
  hotTokenData: any;
  bounceData: any;
};

const state = () => ({
  tokenData: {},
  leverageData: {},
  bubbleData: {},
  winData: {},
  volumeData: 0,
  hotTokenData: {},
  bounceData: {},
});

const mutations: MutationTree<WsState> = {
  SET_TOKEN_DATA(state, tokenData: any) {
    state.tokenData = tokenData;
  },
  SET_LEVERAGE_DATA(state, leverageData: any) {
    state.leverageData = leverageData;
  },
  SET_BUBBLE_DATA(state, bubbleData: any) {
    state.bubbleData = bubbleData;
  },
  SET_WIN_DATA(state, winData: any) {
    state.winData = winData;
  },
  SET_VOLUME_DATA(state, volumeData: any) {
    state.volumeData = volumeData;
  },
  SET_HOT_TOKEN_DATA(state, hotTokenData: any) {
    state.hotTokenData = hotTokenData;
  },
  SET_BOUNCE_DATA(state, bounceData: any) {
    state.bounceData = bounceData;
  },
};

const getters: GetterTree<WsState, RootState> = {
  getTokenData: (state) => state.tokenData,
  getLeverageData: (state) => state.leverageData,
  getBubbleData: (state) => state.bubbleData,
  getWinData: (state) => state.winData,
  getVolumeData: (state) => state.volumeData,
  getHotTokenData: (state) => state.hotTokenData,
  getBounceData: (state) => state.bounceData,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
