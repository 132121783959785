import { GetterTree, MutationTree } from "vuex";

// src/store/modules/launch.ts

type ConfigState = {
  feePublicKey: string;
  pumpupProgramId: string;
};

const state = () => ({
  feePublicKey: "",
  pumpupProgramId: "",
});

const mutations: MutationTree<ConfigState> = {
  SET_CONFIG(state, payload: Partial<ConfigState>) {
    Object.assign(state, payload);
  },
};

const getters: GetterTree<ConfigState, RootState> = {
  GET_CONFIG: (state) => state,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
