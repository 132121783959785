// src/store/index.ts
import { createStore } from "vuex";
import device from "./modules/device";
import user from "./modules/user";
import wallet from "./modules/wallet";
import common from "./modules/common";
import config from "./modules/config";
import solPrice from "./modules/solPrice";
import ws from "./modules/ws";
// 要添加的插件
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  modules: {
    device,
    user,
    wallet,
    common,
    config,
    solPrice,
    ws,
  },
  // 在此处添加插件
  plugins: [createPersistedState()],
});

export default store;
