class WebSocketService {
  socket: WebSocket | null = null; // websockt实例
  private connectTime: number = 0; //断线重连次数
  private readonly url: string;
  private heartbeatTimer: number | null = null; // 心跳定时器
  private readonly heartbeatInterval: number = 20000; // 心跳间隔，30秒
  private messageHandler: ((data: any) => void) | null = null;
  constructor(path: string) {
    // 动态构造 WebSocket URL
    const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
    const host = import.meta.env.DEV
      ? import.meta.env.VITE_API_WS_URL // 开发环境使用配置的地址
      : window.location.origin; // 生产环境使用当前域名
    console.log("websocket.Request.url", host);
    // 组合完整的 URL，包含具体的 API 路径
    this.url = `${protocol}//${host}${path}`;
    console.log(this.url);
  }
  isConnected(): boolean {
    return this.socket !== null && this.socket.readyState === WebSocket.OPEN;
  }
  // 初始化心跳
  private startHeartbeat() {
    this.stopHeartbeat(); // 先清除可能存在的心跳
    this.heartbeatTimer = window.setInterval(() => {
      this.sendHeartbeat();
    }, this.heartbeatInterval);
  }
  // 停止心跳
  private stopHeartbeat() {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }
  }
  // 发送心跳包
  private sendHeartbeat() {
    this.sendMessage(JSON.stringify({ type: "ping" }));
  }
  // 初始化 WebSocket 连接
  init() {
    console.log("init!!!!!!!websocket=>>>>>>>>>>");
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      this.socket = new WebSocket(this.url);
      this.socket.onopen = () => {
        this.onOpen();
        this.startHeartbeat(); // 连接成功后开始心跳
      };
      this.socket.onmessage = this.onMessage;
      this.socket.onerror = this.onError;
      this.socket.onclose = () => {
        this.onClose();
        this.stopHeartbeat(); // 连接关闭时停止心跳
      };
    }
  }
  // WebSocket 连接打开时
  private onOpen = () => {
    console.log("WebSocket connect success");
  };

  // 接收到消息时
  private onMessage = (event: MessageEvent) => {
    if (this.messageHandler) {
      try {
        const data = JSON.parse(event.data);
        this.messageHandler(data);
      } catch (e) {
        this.messageHandler(event.data);
      }
    }
  };

  // 连接出错时
  private onError = (error: Event) => {
    console.error("WebSocket 出错：进行重连", error);
    setTimeout(() => {
      if (this.connectTime >= 30) {
        console.log("已经重连30次； 断开链接");
      } else {
        this.connectTime += 1;
        this.init(); // 重连
      }
    }, 3000);
  };

  // 连接关闭时
  private onClose = () => {
    this.stopHeartbeat(); // 关闭连接时停止心跳
    if (this.socket) {
      this.socket.close();
    }
  };

  // 发送消息
  sendMessage(message: string) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error("WebSocket 连接尚未打开");
    }
  }

  // 关闭连接
  close() {
    if (this.socket) {
      this.socket.close();
    }
  }

  // 设置消息处理器
  setMessageHandler(handler: (data: any) => void) {
    this.messageHandler = handler;
  }
}
export default WebSocketService;
