import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
//扩展dayjs功能
dayjs.extend(relativeTime);
// 截取字符串中间显示省略号
export function getSubStr(str: string) {
  // 判断输入是否为有效字符串
  if (typeof str !== 'string' || !str) {
    return ''; // 如果输入无效，返回空字符串
  }

  // 如果字符串长度小于等于8，直接返回原字符串
  if (str.length <= 8) {
    return str;
  }

  // 截取字符串前4个字符和后4个字符，中间用 "..." 连接
  const prefix = str.slice(0, 4);
  const suffix = str.slice(-4);
  return `${prefix}...${suffix}`;
}

//去除小数末尾为0的部分
// export function removeTrailingZeros(num: string | number): string {
//   if (typeof num === 'string') {
//     num = Number(num);
//   }
//   if (isNaN(num)) {
//     return '0'; // 或者其他默认值
//   }
//   return num.toString().replace(/\.?0+$/, '');
// }

// 千分符分割
export function formatNumberWithCommas(
  num: String | number,
  decimalPlaces = 2
): string {
  if (isNaN(num)) {
      return Number("0.00").toFixed(decimalPlaces);
  }
  if (typeof num == 'string') {
    num = Number(num);
  }

  return num.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const arrayHandler = {
  get(target: any[], property: string | symbol, receiver: any) {
    // 使用 Reflect.get 来获取数组元素
    return Reflect.get(target, property, receiver);
  },
  set(target: any[], property: string | symbol, value: any, receiver: any) {
    // 使用 Reflect.set 来设置数组元素
    return Reflect.set(target, property, value, receiver);
  },
  deleteProperty(target: any[], property: string | symbol) {
    // 使用 Reflect.deleteProperty 来删除数组元素
    return Reflect.deleteProperty(target, property);
  },
};

export function formatCurrency(value: number | string): string {
  const num = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(num) || num == null) {
    return '$0';
  }

  if (num >= 1e6) {
    return `$${(num / 1e6).toFixed(2)}M`;
  } else if (num >= 1e3) {
    return `$${(num / 1e3).toFixed(2)}k`;
  } else {
    return `$${num.toFixed(2)}`;
  }
}

// 格式化时间
/**
 * @description
 * @param date 
 * @returns 
 */
export function formatDate(date: number) {
  return dayjs(date).fromNow();
}

/**
 * 
 * @param num 转换数字为带单位的字符串
 * @param decimalPlaces 
 * @returns 
 */
export function formatNumberUnit(
  num: String | number,
  decimalPlaces = 2
): string {
  // 统一处理输入值
  if (isNaN(Number(num))) {
    return `0`;
  }
  
  const value = typeof num === 'string' ? Number(num) : num;
  const absValue = Math.abs(value);

  // 定义单位映射
  const units = [
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' },
    { value: 1, symbol: '' }
  ];

  // 找到适合的单位
  const unit = units.find(unit => absValue >= unit.value) || units[units.length - 1];
  
  // 计算结果
  const formattedNum = (value / unit.value).toFixed(decimalPlaces);
  
  // 移除末尾多余的0并添加千分符
  const finalNum = removeTrailingZeros(formattedNum)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `${finalNum}${unit.symbol}`;
}

export function removeTrailingZeros(
  num: String
): string {
  if (num) {
    return num.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
  }
  return "";
}

//截取字符串前六位
export function shortAddress(address: string) {
  // 判断大于6位就截取，多余显示省略号
  if (address.length > 6) {
    return address.slice(0, 6) + '...';
  }
  // 否则直接返回
  return address;
}
