<template>
  <div
    class="shake-lr left h5-shake-lr h5-left"
    v-show="currentItem"
    v-if="currentItem?.type === 'HOT_TOKEN'"
  >
    <div class="avatar h5-avatar">
      <img
        style="width: 100%; height: 100%; border-radius: 50%"
        :src="currentItem?.tradeUserImage || '@/assets/profile/avatar.png'"
        alt=""
      />
    </div>
    <div class="text h5-text" :class="{ 'shake-lr': shouldAnimate('user') }">
      {{ currentItem?.tradeUserName }}
    </div>
    <div
      class="bought h5-bought"
      :class="{ 'shake-lr': shouldAnimate('tradeType') }"
    >
      {{ currentItem?.tradeType }}
    </div>
    <div
      class="text h5-text"
      :class="{ 'shake-lr': shouldAnimate('solAmount') }"
    >
      {{ `${currentItem?.solAmount} SOL of ${currentItem?.symbol}` }}
    </div>
    <div class="sol h5-sol">
      <img
        style="width: 100%; height: 100%; border-radius: 50%"
        :src="currentItem?.imageUri || ''"
        alt=""
      />
    </div>
  </div>
  <div
    class="shake-lr left h5-shake-lr h5-left"
    v-show="currentItem"
    v-if="currentItem?.type === 'TOKEN'"
  >
    <div class="avatar h5-avatar">
      <img
        style="width: 100%; height: 100%; border-radius: 50%"
        :src="currentItem?.userImage || '@/assets/profile/avatar.png'"
        alt=""
      />
    </div>
    <div class="text h5-text" :class="{ 'shake-lr': shouldAnimate('user') }">
      {{ currentItem?.username }}
    </div>
    <div
      class="bought h5-bought"
      :class="{ 'shake-lr': shouldAnimate('tradeType') }"
    >
      {{ "Created" }}
    </div>
    <div class="text h5-text" :class="{ 'shake-lr': shouldAnimate('symbol') }">
      {{ currentItem?.symbol }}
    </div>
    <div class="sol h5-sol">
      <img
        style="width: 100%; height: 100%; border-radius: 50%"
        :src="currentItem?.imageUri || ''"
        alt=""
      />
    </div>
    <div class="text h5-text" :class="{ 'shake-lr': shouldAnimate('time') }">
      {{ currentItem?.time }}
    </div>
  </div>
  <div
    class="shake-lr left h5-shake-lr h5-left"
    v-show="currentItem"
    v-if="currentItem?.type === 'LEVERGE'"
  >
    <div
      class="bought h5-bought"
      :class="{ 'shake-lr': shouldAnimate('symbol') }"
    >
      {{ "AI increase leverage of" }}
    </div>
    <div class="text h5-text" :class="{ 'shake-lr': shouldAnimate('symbol') }">
      {{ currentItem?.symbol }}
    </div>
    <div class="sol h5-sol">
      <img
        style="width: 100%; height: 100%; border-radius: 50%"
        :src="currentItem?.icon || ''"
        alt=""
      />
    </div>
    <div class="text h5-text" :class="{ 'shake-lr': shouldAnimate('time') }">
      {{ currentItem?.time }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps, watch, ref } from "vue";

const props = defineProps<{
  currentItem: any;
}>();

const previousValues = ref<any>({});

const shouldAnimate = (field: string) => {
  return previousValues.value[field] !== props.currentItem?.[field];
};
watch(
  () => props.currentItem,
  (newVal, oldVal) => {
    if (oldVal) {
      previousValues.value = { ...oldVal };
    }
  },
  { deep: true }
);
</script>
<style lang="scss" scoped>
.shake-lr {
  cursor: pointer;
  animation: shake-lr 1s both;
}

.left {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  margin-right: 10px;
  height: 30px;
  background-color: #31c01c;
}

.avatar {
  height: 15px;
  width: 15px;
  margin-right: 4px;
}

.text {
  font-size: 14px;
  color: #110b0b;
}

.bought {
  color: yellow;
  font-size: 14px;
  margin: 0 4px;
}

.sol {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 4px;
}

@keyframes shake-lr {
  0% {
    transform: translateX(-48px);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
    background-color: #44b233;
  }
  40% {
    transform: translateX(-26px);
    animation-timing-function: ease-in;
    background-color: #d33930;
  }
  65% {
    transform: translateX(-13px);
    animation-timing-function: ease-in;
    background-color: #7b652f;
  }
  82% {
    transform: translateX(-6.5px);
    animation-timing-function: ease-in;
    background-color: #7b652f;
  }
  93% {
    transform: translateX(-4px);
    animation-timing-function: ease-in;
    background-color: #44b233;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateX(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
</style>
